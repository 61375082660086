import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';
import * as onUpdateEffects from './onUpdateEffects';

const dependentProperties = {
  DESCRIPTION: 'description',
  IS_INFORM: 'isInform',
  MEDIA: 'media',
  CLIENT: 'client',
  PROJECT: 'project',
  BUILDING: 'building',
  UNIT: 'unit',
  TASK_DATE_LAST: 'issueDateLast',
  TASK_DATE_FIRST: 'issueDateFirst',
};

/** @type {import('./schema').default}  */
export const schema = [
  { type: schemaItemTypes.STRING, label: 'task.title', prop: 'name', rules: [rules.REQUIRED] },
  {
    type: schemaItemTypes.STRING,
    prop: dependentProperties.DESCRIPTION,
    label: 'label.description',
    textarea: true,
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.DATE,
    label: 'task.date_first_task',
    prop: dependentProperties.TASK_DATE_FIRST,
    rules: [rules.REQUIRED],
    tooltip: { text: 'invoice.invoice_date_first_tooltip', icon: 'mdi-information' },
    onlyFutureDate: true,
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.TASK_DATE_LAST,
      },
    ],
  },
  {
    type: schemaItemTypes.ENUM,
    label: 'task.recurrence_period',
    prop: 'recurrencePeriod',
    rules: [rules.REQUIRED],
    dictionary: dictionaryTypes.RECURRENCE_PERIODS,
  },
  {
    type: schemaItemTypes.DATE,
    label: 'task.date_last_task',
    prop: dependentProperties.TASK_DATE_LAST,
    rules: [rules.REQUIRED],
    payload: [
      {
        param: 'minDate',
        from: [dependentProperties.TASK_DATE_FIRST],
        serializer: serializers.NEXT_DAY,
      },
    ],
  },
  {
    type: schemaItemTypes.CLIENT,
    label: 'label.client',
    prop: dependentProperties.CLIENT,
    payload: [
      { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
      { param: 'unitId', from: [dependentProperties.UNIT, 'id'] },
    ],

    onUpdate: [
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: dependentProperties.PROJECT,
      },
    ],
  },
  {
    type: schemaItemTypes.PROJECT,
    label: 'label.project',
    prop: dependentProperties.PROJECT,
    rules: [rules.REQUIRED],
    payload: [{ param: 'clientId', from: [dependentProperties.CLIENT, 'id'] }],
    onUpdate: [
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: dependentProperties.BUILDING,
      },
      {
        type: onUpdateEffects.REFILL,
        to: dependentProperties.CLIENT,
      },
    ],
  },
  {
    type: schemaItemTypes.BUILDING,
    label: 'label.building',
    prop: 'building',
    rules: [rules.REQUIRED],
    payload: [
      { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: dependentProperties.PROJECT,
        from: 'project',
      },
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: dependentProperties.UNIT,
      },
    ],
  },
  {
    type: schemaItemTypes.UNIT,
    label: 'label.unit',
    prop: 'unit',
    rules: [rules.REQUIRED],
    payload: [
      { param: 'buildingId', from: [dependentProperties.BUILDING, 'id'] },
      { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: dependentProperties.BUILDING,
        from: 'building',
      },
    ],
  },
  {
    type: schemaItemTypes.ENUM,
    label: 'label.service-types',
    prop: 'serviceType',
    dictionary: dictionaryTypes.SERVICE_TYPES,
    rules: [rules.REQUIRED],
  },
  { type: schemaItemTypes.NUMBER, label: 'task.cost', prop: 'price' },
  {
    type: schemaItemTypes.EMPLOYEES,
    label: 'label.executor',
    prop: 'performer',
    rules: [rules.REQUIRED],
  },

  {
    type: schemaItemTypes.BOOLEAN,
    prop: 'isInformClientViaPush',
    label: 'tasks.change_status_checkbox_inform',
  },
  {
    label: 'label.attached_files',
    prop: 'media',
    type: schemaItemTypes.FILE_LIST,
    rules: [rules.ALL_LOADED],
    editable: true,
    accept: '*',
  },
];

export { schema as default };
